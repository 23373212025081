import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Button,
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { apiService } from 'services/apiServices';

const Templates = () => {
  const [templates, setTemplates] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    apiService.getTemplates().then((data) => {
      setTemplates(data);
    });
  }, []);

  const addTemplate = () => {
    // Add your logic to navigate to the add template page here
    console.log('Add template');
    navigate('/templates/add');
  };

  const editTemplate = (id) => {
    // Add your logic to navigate to the edit template page here
    console.log(`Edit template with id: ${id}`);
    navigate(`/templates/edit/${id}`);
  };

  return (
    <MainCard title="Templates" content="This is the Templates page">
      <CardContent>
        <Button onClick={addTemplate} variant="contained" color="primary">
          Add Template
        </Button>
        {templates.map((template) => (
          <Card key={template.id} sx={{ mb: 2 }}>
            <CardContent>
              <Typography variant="h6" component="div">
                {template.name}
              </Typography>
              <IconButton
                onClick={() => editTemplate(template._id)}
                aria-label="Edit"
                color="primary"
              >
                <Edit />
                {/* Add your edit icon component here */}
              </IconButton>
              <IconButton aria-label="Delete" color="secondary">
                <Delete />
                {/* Add your delete icon component here */}
              </IconButton>
            </CardContent>
          </Card>
        ))}
      </CardContent>
    </MainCard>
  );
};

export default Templates;
