// assets
import {
  IconDashboard,
  IconUsers,
  IconLocation,
  IconCe,
  IconCertificate,
  IconTemplate,
} from '@tabler/icons';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: 'dashboard',
  title: 'Dashboard',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard/default',
      icon: IconDashboard,
      breadcrumbs: false,
    },
    {
      id: 'cartificates',
      title: 'Certificates',
      type: 'item',
      url: '/cartificates',
      icon: IconCertificate,
      breadcrumbs: false,
    },
    {
      id: 'templates',
      title: 'Templates',
      type: 'item',
      url: '/templates',
      icon: IconTemplate,
      breadcrumbs: false,
    },
  ],
};

export default dashboard;
