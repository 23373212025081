// assets
import { IconKey } from '@tabler/icons';

// constant
const icons = {
  IconKey,
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
  id: 'pages',
  title: 'Adminstration',
  caption: 'Accounts',
  type: 'group',
  children: [
    {
      id: 'authentication',
      title: 'User Management',
      type: 'collapse',
      icon: icons.IconKey,

      children: [
        {
          id: 'users',
          title: 'Users',
          type: 'item',
          url: '/pages/users',
        },

        {
          id: 'login3',
          title: 'My Profile',
          type: 'item',
          url: '/pages/users/add-new',
        },
        {
          id: 'login3',
          title: 'Add User',
          type: 'item',
          url: '/pages/users/add-new',
        },
        {
          id: 'login3',
          title: 'Login',
          type: 'item',
          url: '/pages/login',
          target: true,
        },
        {
          id: 'register3',
          title: 'Register',
          type: 'item',
          url: '/pages/register/register3',
          target: true,
        },
      ],
    },
  ],
};

export default pages;
