import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { apiService } from 'services/apiServices';
import { useNavigate, useParams } from 'react-router';
import Dropzone from 'react-dropzone';
import StatusChip from './statusChip';
import ApproveButton from './approveButton';
import GroupsIcon from '@mui/icons-material/Groups';
import { gridSpacing } from 'store/constant';

const PlaceView = (props) => {
  const [placeDetails, setPlaceDetails] = React.useState({});
  const [placeStatus, setPlaceStatus] = React.useState({
    capacity: 50,
    live: 20,
    lastUpdated: new Date(),
  });
  const { id } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await apiService.getPlace(id);
      setPlaceDetails(response.place);
    };
    fetchData();
  }, [id]);

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await apiService.getPlaceStatus(id);
      setPlaceStatus({
        live: response.liveStatus?.currentCrowd || 0,
        capacity: 50,
        lastUpdated: new Date(),
      });
    };
    fetchData();
  }, [id]);

  if (!placeDetails) {
    return <div>Loading...</div>;
  }

  const handleDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const formData = new FormData();
    formData.append('file', file);
    const response = await apiService.uploadPlacePhoto(id, formData);
    console.log(response);
  };

  const changeSlider = (e, value) => {
    console.log('Slider:', value);
    setPlaceStatus({ ...placeStatus, live: value });
    // apiService.updatePlaceStatus(id, { live: value });
  };

  const onChangeCommitted = (e, value) => {
    console.log('Committed:', value);
    apiService.updatePlaceStatus(id, { live: value });
  };

  const placeColor = (placeStatus) => {
    const percentage = (placeStatus.live * 100) / placeStatus.capacity;
    console.log('Percentage:', percentage);
    if (percentage < 50) {
      return 'success';
    } else if (percentage < 80) {
      return 'primary';
    } else {
      return 'error';
    }
  };

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item lg={6} md={6} sm={8} xs={12}>
        <MainCard title={'Place Details'}>
          <CardContent>
            <h3>
              {placeDetails.name}
              <Button
                onClick={() => navigate('/places/edit/' + placeDetails._id)}
              >
                Edit
              </Button>
            </h3>{' '}
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Description:</TableCell>
                  <TableCell>
                    <strong>{placeDetails.description}</strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Location:</TableCell>
                  <TableCell>
                    <strong>{placeDetails.location}</strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>City:</TableCell>
                  <TableCell>
                    <strong>{placeDetails.city}</strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Address:</TableCell>
                  <TableCell>
                    <strong>{placeDetails.address}</strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Country:</TableCell>
                  <TableCell>
                    <strong>{placeDetails.country}</strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Place Type:</TableCell>
                  <TableCell>
                    <strong>{placeDetails.type}</strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Rating:</TableCell>
                  <TableCell>
                    <strong>{placeDetails.ratings}</strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Reviews:</TableCell>
                  <TableCell>
                    <strong>{placeDetails.reviews}</strong>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Tags:</TableCell>
                  <TableCell>
                    <strong>{placeDetails.tags}</strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Popularity Score:</TableCell>
                  <TableCell>
                    <strong>{placeDetails.popularityScore}</strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Max Capacity:</TableCell>
                  <TableCell>
                    <strong>{placeDetails.maxCapacity}</strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Average Time Spend:</TableCell>
                  <TableCell>
                    <strong>{placeDetails.averageTimeSpend}</strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Contact Person:</TableCell>
                  <TableCell>
                    <strong>{placeDetails.contactPerson}</strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Email:</TableCell>
                  <TableCell>
                    <strong>{placeDetails.email}</strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Phone:</TableCell>
                  <TableCell>
                    <strong>{placeDetails.phoneNumber}</strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </MainCard>
      </Grid>
      <Grid item lg={6} md={6} sm={4} xs={12}>
        <MainCard title={'Current Status'} spacing={gridSpacing}>
          <CardContent>
            <div>
              <Typography variant="h6">Current Status</Typography>
              <GroupsIcon color={placeColor(placeStatus)} />
              {placeColor(placeStatus) !== 'success' ? (
                <GroupsIcon color={placeColor(placeStatus)} />
              ) : null}
              {placeColor(placeStatus) === 'error' ? (
                <GroupsIcon color={placeColor(placeStatus)} />
              ) : null}
              {placeColor(placeStatus) === 'error' ? (
                <GroupsIcon color={placeColor(placeStatus)} />
              ) : null}

              <Slider
                color={placeColor(placeStatus)}
                onChange={changeSlider}
                onChangeCommitted={onChangeCommitted}
                value={placeStatus.live}
                max={placeStatus.capacity}
                aria-label="Default"
                valueLabelDisplay="auto"
              />
              <TextField
                label="Max Capacity"
                variant="outlined"
                value={placeStatus.capacity}
              />
            </div>
          </CardContent>
        </MainCard>
        <MainCard title={'Status'} sx={{ mt: 3 }}>
          <CardContent>
            <div style={{ marginTop: '20px' }}>
              <Typography variant="h6">Status</Typography>
              <StatusChip status={placeDetails.status} />{' '}
              <ApproveButton id={placeDetails._id} />
            </div>
            <div style={{ marginTop: '20px', textAlign: 'center' }}>
              <Typography variant="h6">Photos</Typography>
              <img
                src={placeDetails.photos}
                alt={placeDetails.name}
                style={{ width: '100%', maxWidth: '500px' }}
              />
            </div>
            <Grid item xs={12} md={6}>
              <div style={{ marginTop: '20px', textAlign: 'center' }}>
                <Dropzone onDrop={handleDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()} className="dropzone">
                      <input {...getInputProps()} />
                      <p>Drag and drop files here, or click to select files</p>
                    </div>
                  )}
                </Dropzone>
              </div>
            </Grid>

            <Button variant="contained" color="primary">
              Add Photos
            </Button>
          </CardContent>
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default PlaceView;
