import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import PlaceView from 'views/certificates/view';
import EditUser from 'views/users/edit';
import Templates from 'views/certificates/templates';
import AddTemplate from 'views/certificates/add-template';

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import('views/dashboard/Default'))
);

// sample page routing
const Certificates = Loadable(lazy(() => import('views/certificates')));
const AddPlace = Loadable(lazy(() => import('views/certificates/add-new')));
const EditPlace = Loadable(lazy(() => import('views/certificates/edit')));
const Visitors = Loadable(lazy(() => import('views/visitors')));

const Users = Loadable(lazy(() => import('views/users')));
const AddUSer = Loadable(lazy(() => import('views/users/add-new')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <DashboardDefault />,
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <DashboardDefault />,
        },
      ],
    },
    {
      path: 'visiters',
      children: [
        {
          path: 'visiters',
          element: <Visitors />,
        },
      ],
    },

    {
      path: 'cartificates',
      element: <Certificates />,
    },
    {
      path: 'templates',
      element: <Templates />,
    },
    {
      path: 'templates/add',
      element: <AddTemplate />,
    },
    {
      path: 'templates/edit/:id',
      element: <AddTemplate />,
    },
    {
      path: 'pages/users/add-new',
      element: <AddUSer />,
    },
    {
      path: 'pages/users',
      element: <Users />,
    },
    {
      path: 'pages/users/:id',
      element: <EditUser />,
    },
    {
      path: 'places/:id',
      element: <PlaceView />,
    },
    {
      path: 'visitors',
      element: <Visitors />,
    },
  ],
};

export default MainRoutes;
