// apiService.js

import axios from 'axios';
import { API_ENDPOINTS } from './apiEndpoints';
import AuthManagementService from './auth'; // Import the getAccessToken function from authService

export const apiService = {
  getUsers: async () => {
    const response = await axios.get(API_ENDPOINTS.getUsers);
    return response.data;
  },

  getUser: async (id) => {
    const response = await axios.get(API_ENDPOINTS.getUser(id));
    return response.data;
  },

  registerUser: async (userData) => {
    const response = await axios.post(API_ENDPOINTS.registerUser, userData);
    if (response.status === 201) {
      localStorage.setItem('token', response.data.tokens.access.token);
      localStorage.setItem('refreshToken', response.data.tokens.refresh.token);
      localStorage.setItem('expires', response.data.tokens.access.expires);
      return response.data;
    }
  },

  login: async (userData) => {
    const response = await axios.post(API_ENDPOINTS.login, userData);
    if (response.status === 200) {
      localStorage.setItem('token', response.data.tokens.access.token);
      localStorage.setItem('refreshToken', response.data.tokens.refresh.token);
      localStorage.setItem('expires', response.data.tokens.access.expires);
      return response.data;
    }
  },

  logout: async () => {
    const response = await axios.post(API_ENDPOINTS.logout);
    if (response.status === 200) {
      localStorage.removeItem('token');
      return response.data;
    }
  },

  generateCertificate: async (data) => {
    // const accessToken = await AuthManagementService.getAccessToken(); // Use the getAccessToken function
    const headers = {
      //  Authorization: 'Bearer ' + accessToken,
    };
    const response = await axios.post(API_ENDPOINTS.generateCertificate, data, {
      headers,
    });
    return response.data;
  },

  renewAccessToken: async () => {
    const response = await axios.post(API_ENDPOINTS.renewAccessToken);
    if (response.status === 200) {
      localStorage.setItem('token', response.data.tokens.access.token);
      return response.data;
    }
  },

  updatePlaceStatus: async (id, status) => {
    const accessToken = await AuthManagementService.getAccessToken(); // Use the getAccessToken function
    const headers = {
      Authorization: 'Bearer ' + accessToken,
    };
    const response = await axios.patch(
      API_ENDPOINTS.updatePlaceStatus(id),
      { currentCrowd: status.live },
      {
        headers,
      }
    );
    return response.data;
  },

  getPlaceStatus: async (id) => {
    const accessToken = await AuthManagementService.getAccessToken(); // Use the getAccessToken function
    const headers = {
      Authorization: 'Bearer ' + accessToken,
    };
    const response = await axios.get(API_ENDPOINTS.updatePlaceStatus(id), {
      headers,
    });
    return response.data;
  },

  createTemplate: async (templateData) => {
    //  const accessToken = await AuthManagementService.getAccessToken(); // Use the getAccessToken function
    const headers = {
      //    Authorization: 'Bearer ' + accessToken,
    };
    const response = await axios.post(
      API_ENDPOINTS.createTemplate,
      templateData,
      {
        headers,
      }
    );
    return response.data;
  },

  updateTemplate: async (templateData, id) => {
    //  const accessToken = await AuthManagementService.getAccessToken(); // Use the getAccessToken function
    const headers = {
      //    Authorization: 'Bearer ' + accessToken,
    };
    const response = await axios.put(
      API_ENDPOINTS.createTemplate + '/' + id,
      templateData,
      {
        headers,
      }
    );
    return response.data;
  },

  getTemplates: async () => {
    // const accessToken = await AuthManagementService.getAccessToken(); // Use the getAccessToken function
    const headers = {
      // Authorization: 'Bearer ' + accessToken,
    };
    const response = await axios.get(API_ENDPOINTS.createTemplate, {
      headers,
    });
    return response.data;
  },

  getTemplate: async (id) => {
    // const accessToken = await AuthManagementService.getAccessToken(); // Use the getAccessToken function
    const headers = {
      // Authorization: 'Bearer ' + accessToken,
    };
    const response = await axios.get(API_ENDPOINTS.createTemplate + '/' + id, {
      headers,
    });
    return response.data;
  },
};
