import React, { useState, useEffect } from 'react';
import { TextField, Button, CardContent, Grid, Snackbar } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { apiService } from 'services/apiServices';
import { useNavigate, useParams } from 'react-router';

const AddTemplate = (props) => {
  const [templateData, setTemplateData] = useState({
    name: '',
    background: '',
    texts: [
      {
        name: '',
        left: '',
        top: '',
        color: '',
        size: '',
      },
    ],
  });

  const params = useParams();
  const { id } = params;
  const navigator = useNavigate();
  console.log(id);
  useEffect(() => {
    if (!id) return;
    apiService.getTemplate(id).then((data) => {
      setTemplateData(data);
    });
  }, [id]);

  const handleChange = (e) => {
    console.log(e.target.name, e.target.value);
    setTemplateData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleTextChange = (name, index, e) => {
    const newTemplateData = { ...templateData };
    newTemplateData.texts[index][name] = e.target.value;
    setTemplateData(newTemplateData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (id) {
      await apiService.updateTemplate(templateData, id);
      //Snackbar.success('Template updated successfully');
      navigator('/templates');

      return;
    }
    await apiService.createTemplate(templateData);
    //Snackbar.success('Template created successfully');
    navigator('/templates');

    // Add your logic to handle form submission here
  };

  return (
    <MainCard title="Templates" content="This is the Templates page">
      <CardContent>
        <form onSubmit={handleSubmit}>
          <TextField
            name="name"
            label="Template Name"
            value={templateData.name}
            onChange={handleChange}
            margin="normal"
            variant="filled"
            sx={{ mr: 2 }}
          />
          <TextField
            name="background"
            label="Background Image URL"
            value={templateData.background}
            sx={{ width: 355 }}
            onChange={handleChange}
            margin="normal"
            variant="filled"
          />
          <Grid container spacing={2}>
            {templateData.texts.map((text, index) => (
              <Grid key={index} item>
                <TextField
                  name="name[]"
                  variant="filled"
                  label="Field Name"
                  onChange={(e) => handleTextChange('name', index, e)}
                  value={text.name}
                  sx={{ mr: 2 }}
                />
                <TextField
                  name="left"
                  variant="filled"
                  label="Left"
                  onChange={(e) => handleTextChange('left', index, e)}
                  value={text.left}
                  sx={{ mr: 2 }}
                />
                <TextField
                  name="top"
                  variant="filled"
                  label="Top"
                  onChange={(e) => handleTextChange('top', index, e)}
                  value={text.top}
                  sx={{ mr: 2 }}
                />
                <TextField
                  name="color"
                  variant="filled"
                  label="Font Color"
                  onChange={(e) => handleTextChange('color', index, e)}
                  value={text.color}
                  sx={{ mr: 2 }}
                />
                <TextField
                  name="size"
                  variant="filled"
                  label="Font Size"
                  onChange={(e) => handleTextChange('size', index, e)}
                  value={text.size}
                  sx={{ mr: 2 }}
                />
              </Grid>
            ))}
          </Grid>
          {/* Add more form fields here */}
          <Button
            sx={{ mt: 2 }}
            type="submit"
            variant="contained"
            color="primary"
          >
            {id ? 'Update Template' : 'Create Template'}
          </Button>
        </form>
      </CardContent>
    </MainCard>
  );
};

export default AddTemplate;
