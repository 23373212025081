// apiEndpoints.js

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

export const API_ENDPOINTS = {
  getUsers: `${API_BASE_URL}/v1/users`,
  getUser: (id) => `${API_BASE_URL}/v1/users/${id}`,
  registerUser: `${API_BASE_URL}/v1/auth/register`,
  login: `${API_BASE_URL}/v1/auth/login`,
  logout: `${API_BASE_URL}/v1/auth/logout`,
  renewAccessToken: `${API_BASE_URL}/v1/auth/refresh-tokens`,

  generateCertificate: `${API_BASE_URL}/v1/certs/create-certificate`,
  createTemplate: `${API_BASE_URL}/v1/certs/templates`,
  // Add more endpoints as needed
};
